import type {
  AnnotatedGraph,
  AnnotatedNodeT,
  AnnotatedSuperNodeT,
  Annotation,
  AnnotationsMap,
  Arrow,
  ArrowType,
  DisplayMode,
  Edge,
  FlattenedLine,
  GraphGroup,
  LineArrows,
  ModeOfSpeech,
  ModesOfSpeechMap,
  ModesOfSpeechMapItem,
  Node,
  Graph,
  Group,
  Line,
  SubGroup,
} from '../types';

const remapAnnotations = (
  annotations: (Annotation & { highlight?: boolean; underline?: boolean })[]
): AnnotationsMap =>
  annotations.reduce((currMap, annotation) => {
    const {
      annotator_uuid: uuid,
      matches,
      name,
      type,
      value,
      highlight,
      underline,
    } = annotation;
    let annotationName = name;
    if (value !== 1) annotationName += ` ${Math.round(value * 1000) / 1000}`;
    const map = { ...currMap };
    matches.forEach((match) => {
      match.nodes.forEach((node) => {
        const key = `${match.sentence}.${node}`;
        let actualNodes: number[] = [];

        annotations
          .filter(
            (annot) => annot.matches.length > 0 && annot.annotator_uuid === uuid
          )
          .forEach((annot) => {
            annot.matches.forEach((matchedNode) => {
              actualNodes = actualNodes.concat(matchedNode.nodes);
            });
          });

        const item = {
          uuid,
          name: annotationName,
          type,
          matches: actualNodes,
          highlight,
          underline,
        };
        map[key] = map[key] ? [...map[key], item] : [item];
      });
    });

    return map;
  }, {} as AnnotationsMap);

const flattenGroupsIntoLines = (
  graphs: Graph[],
  groups: GraphGroup[]
): FlattenedLine[] => {
  const flattened: FlattenedLine[] = [];

  groups.forEach((group) => {
    if (group.type === 'subject') {
      const current: Graph[] = [];
      group.nodes.forEach((idx) => {
        const node = graphs[idx];
        current.push(node);
      });
      if (current.length !== 0) {
        flattened.push({ type: 'subject', nodes: current });
      }
      return;
    }

    const current: Graph[] = [];
    group.nodes.forEach((idx) => {
      const node = graphs[idx];
      current.push(node as Graph);
    });

    if (current.length !== 0) {
      flattened.push({ type: 'line', nodes: current });
    }
  });

  return flattened;
};

const flattenGroupsIntoLinesLegacy = (groups: Group[]): FlattenedLine[] => {
  const flattened: FlattenedLine[] = [];

  groups.forEach((group) => {
    if (group.type === 'subject') {
      const current: Graph[] = [];
      group.nodes.forEach((node: Graph) => {
        current.push(node);
      });
      if (current.length !== 0) {
        flattened.push({ type: 'subject', nodes: current });
      }
      return;
    }

    let current: Graph[] = [];
    group.nodes.forEach((node: Line | SubGroup | Graph) => {
      if ((node as SubGroup).type === 'sub-group') {
        if (current.length !== 0) {
          flattened.push({ type: 'line', nodes: current });
          current = [];
        }

        flattened.push(
          ...flattenGroupsIntoLinesLegacy((node as SubGroup).nodes)
        );
        return;
      }

      current.push(node as Graph);
    });

    if (current.length !== 0) {
      flattened.push({ type: 'line', nodes: current });
    }
  });

  return flattened;
};

const annotateSentence = (
  nodes: { [index: string]: Node },
  annotationsMap: AnnotationsMap,
  sentenceIdx: string | number,
  startIdx: number,
  modesOfSpeech: ModesOfSpeechMapItem = {}
): { sentence: AnnotatedSuperNodeT[]; lastIdx: number } => {
  let superNodeIdx = 0;
  const sentence: AnnotatedSuperNodeT[] = [];
  Object.values(nodes).forEach((node) => {
    const currentIdx = superNodeIdx + startIdx;

    const nodeAnnotations = annotationsMap[`${sentenceIdx}.${node.index}`];
    const nodeInfo: AnnotatedNodeT = {
      index: node.index,
      text: node.text,
      pos: node.pos,
      mos: modesOfSpeech[node.index],
      lemma: node.lemma,
      entity: node.ent_type,
      tense: node.tense,
      negated: node.negated,
      suggestions: node.suggestions,
    };

    if (nodeAnnotations == null) {
      superNodeIdx += 1;
      sentence.push({ index: currentIdx, nodes: [nodeInfo] });
      return;
    }

    if (
      sentence.length === 0 ||
      sentence[superNodeIdx - 1].annotations == null
    ) {
      superNodeIdx += 1;
      sentence.push({
        index: currentIdx,
        annotations: nodeAnnotations,
        nodes: [nodeInfo],
      });
      return;
    }

    const prevAnnotations = sentence[superNodeIdx - 1].annotations;
    if (
      prevAnnotations != null &&
      (prevAnnotations.length !== nodeAnnotations.length ||
        !prevAnnotations.every((ann) => nodeAnnotations.includes(ann)))
    ) {
      superNodeIdx += 1;
      sentence.push({
        index: currentIdx,
        annotations: nodeAnnotations,
        nodes: [nodeInfo],
      });
      return;
    }

    sentence[superNodeIdx - 1].nodes.push(nodeInfo);
  }, []);

  return { sentence, lastIdx: superNodeIdx + startIdx };
};

// There should only be 2 modes of speech: subject, and objects
export const remapModesOfSpeech = (
  subjects: ModeOfSpeech,
  objects: ModeOfSpeech
): ModesOfSpeechMap => {
  const config: ModesOfSpeechMap = {};

  const mapModeOfSpeech = (
    modeOfSpeech: ModeOfSpeech,
    mode: DisplayMode
  ): void => {
    Object.entries(modeOfSpeech).forEach(([sentence, nodeIdxs]) => {
      nodeIdxs.forEach((nodeIdx) => {
        config[sentence] = {
          ...config[sentence],
          [nodeIdx]: mode,
        };
      });
    });
  };

  mapModeOfSpeech(subjects, 'Subject');
  mapModeOfSpeech(objects, 'Object');

  return config;
};

export const annotateEventSentences = (
  sentences: { [line: string]: Graph },
  annotations: Annotation[]
): { sentences: AnnotatedGraph[]; arrows: LineArrows[] } => {
  const annotationsMap = remapAnnotations(annotations);

  // this works if the sentences and nodes objects always comes with the keys
  // ordered (0, 1, 2, ...), if that is not going to happen, some sort of
  // ordering must be done before doing this
  const annotated: AnnotatedGraph[] = [];
  const arrows: LineArrows[] = [];
  Object.entries(sentences).forEach(([sentenceIdx, sentence]) => {
    const { sentence: annotatedSentence } = annotateSentence(
      sentence.nodes,
      annotationsMap,
      sentenceIdx,
      0
    );

    annotated.push({
      index: parseInt(sentenceIdx, 10),
      nodes: annotatedSentence,
    });
    arrows.push({
      index: parseInt(sentenceIdx, 10),
      arrows: sentence.edges.map((e) => ({ ...e, type: 'edge' })),
    });
  }, []);

  return { sentences: annotated, arrows };
};

export const annotateCommunicationGroups = (
  graphs: Graph[],
  groups: GraphGroup[],
  annotations: Annotation[],
  rawRelationships?: { [line: string]: Edge[] } | null,
  modesOfSpeech: ModesOfSpeechMap = {}
): {
  lines: AnnotatedGraph[];
  arrows: LineArrows[];
} => {
  const annotationsMap = remapAnnotations(annotations);

  let sentenceIdx = -1;
  const lines: AnnotatedGraph[] = [];
  const arrows: LineArrows[] = [];

  flattenGroupsIntoLines(graphs, groups).forEach((group, groupIdx) => {
    let nodeIdx = 0;
    const line: AnnotatedSuperNodeT[] = [];
    const lineArrows: Arrow[] = [];
    group.nodes.forEach((groupNode) => {
      sentenceIdx += 1;
      const { sentence, lastIdx } = annotateSentence(
        groupNode.nodes,
        annotationsMap,
        sentenceIdx,
        nodeIdx,
        modesOfSpeech[sentenceIdx]
      );

      line.push(...sentence);

      lineArrows.push(
        ...groupNode.edges.map((e) => ({
          ...e,
          head: e.head + nodeIdx,
          root: e.root + nodeIdx,
          type: 'edge' as ArrowType,
        }))
      );
      if (rawRelationships != null) {
        lineArrows.push(
          ...rawRelationships[sentenceIdx].map((r) => ({
            ...r,
            head: r.head + nodeIdx,
            root: r.root + nodeIdx,
            type: (r.relationship === 'dependent_on'
              ? 'dependent'
              : 'relationship') as ArrowType,
          }))
        );
      }
      nodeIdx = lastIdx;
    });
    lines.push({ index: groupIdx, nodes: line, type: group.type });
    arrows.push({ index: groupIdx, arrows: lineArrows });
  });

  return { lines, arrows };
};

export const annotateCommunicationGroupsLegacy = (
  groups: Group[],
  annotations: Annotation[],
  rawRelationships?: { [line: string]: Edge[] } | null,
  modesOfSpeech: ModesOfSpeechMap = {}
): {
  lines: AnnotatedGraph[];
  arrows: LineArrows[];
} => {
  const annotationsMap = remapAnnotations(annotations);

  let sentenceIdx = -1;
  const lines: AnnotatedGraph[] = [];
  const arrows: LineArrows[] = [];

  flattenGroupsIntoLinesLegacy(groups).forEach((group, groupIdx) => {
    let nodeIdx = 0;
    const line: AnnotatedSuperNodeT[] = [];
    const lineArrows: Arrow[] = [];
    group.nodes.forEach((groupNode) => {
      sentenceIdx += 1;
      const { sentence, lastIdx } = annotateSentence(
        groupNode.nodes,
        annotationsMap,
        sentenceIdx,
        nodeIdx,
        modesOfSpeech[sentenceIdx]
      );

      line.push(...sentence);

      lineArrows.push(
        ...groupNode.edges.map((e) => ({
          ...e,
          head: e.head + nodeIdx,
          root: e.root + nodeIdx,
          type: 'edge' as ArrowType,
        }))
      );
      if (rawRelationships != null) {
        lineArrows.push(
          ...rawRelationships[sentenceIdx].map((r) => ({
            ...r,
            head: r.head + nodeIdx,
            root: r.root + nodeIdx,
            type: (r.relationship === 'dependent_on'
              ? 'dependent'
              : 'relationship') as ArrowType,
          }))
        );
      }
      nodeIdx = lastIdx;
    });
    lines.push({ index: groupIdx, nodes: line, type: group.type });
    arrows.push({ index: groupIdx, arrows: lineArrows });
  });

  return { lines, arrows };
};

export const remapNodesHighlighted = (
  nodes: AnnotatedSuperNodeT[]
): AnnotatedSuperNodeT[] => {
  const realNodes: AnnotatedSuperNodeT[] = [];

  let sentenceNodes: AnnotatedSuperNodeT = {
    annotations: [],
    index: 0,
    nodes: [],
  };

  nodes.forEach((superNode, idx) => {
    if (superNode.annotations == null) {
      realNodes.push(superNode);
      return;
    }

    const isSentenceType = superNode.annotations.some(
      (annotation) => annotation.type === 'sentence'
    );

    if (!isSentenceType) {
      realNodes.push(superNode);
      return;
    }

    const allSentenceTypes = superNode.annotations.every(
      (annotation) => annotation.type === 'sentence'
    );

    if (allSentenceTypes) {
      superNode.nodes.forEach((node) => {
        sentenceNodes.nodes.push(node);
      });
    } else {
      superNode.nodes.forEach((node) => {
        const newNode = node;
        newNode.multipleHighlight = true;
        sentenceNodes.nodes.push(newNode);
      });
    }

    superNode.annotations.forEach((annotation) => {
      if (
        sentenceNodes.annotations?.findIndex(
          (x) => x.uuid === annotation.uuid
        ) === -1
      ) {
        sentenceNodes.annotations.push(annotation);
      }
    });

    const nextNode = nodes[idx + 1];

    if (
      nodes.length - 1 === idx ||
      !nextNode.annotations ||
      nextNode.annotations.every((annotation) => annotation.type !== 'sentence')
    ) {
      sentenceNodes.index = superNode.index;
      realNodes.push(sentenceNodes);

      sentenceNodes = {
        annotations: [],
        index: 0,
        nodes: [],
      };
    }
  });

  let found: AnnotatedSuperNodeT['sentenceHighlight'];

  realNodes.forEach((n, idx) => {
    const oIdx = n.nodes[0].index;
    if (oIdx === 0) {
      found = undefined;
    }

    if (oIdx === 0) {
      let i = idx + oIdx + 1;
      // eslint-disable-next-line no-constant-condition
      while (true) {
        if (!realNodes[i]) break;
        const node = realNodes[i];

        const { index } = node.nodes[0];
        if (index === 0) break;
        if (node.annotations) {
          if (node.annotations[0].highlight) {
            found = 'highlight';
            break;
          }
          if (node.annotations[0].underline) found = 'underline';
        }
        i += 1;
      }
    }
    if (found) {
      realNodes[idx].sentenceHighlight = found;
    }
  });

  return realNodes;
};
